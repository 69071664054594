<template>
<div>
    <div class="wrap">
        <div class="con_top">
            <h2><span>■</span> 관리자등록</h2>
        </div>
        <div class="con">
            <div class="con_table">
                <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                    <tbody>
                        <tr>
                            <th style="width:111px;">아이디 <span class="red">*</span></th>
                            <td class="left">
                                <input type="text" v-model="uid" class="ml-20" style="width:200px;" id="uid" name="uid" value="">
                                <button type="button" class="default-btn ml-20" @click="checkID()">중복체크</button><br>
                                <span class="ml-20"> 영문 대/소문자 + 숫자포함 6~20자</span>

                            </td>
                        </tr>
                        <tr>
                            <th>암호 <span class="red">*</span></th>
                            <td class="left">
                                <input type="password" v-model="upw" class="ml-20" style="width:200px;" id="upw" name="upw" value=""> <br>
                                <span class="ml-20"> 영문 대/소문자 + 숫자 + 특수문자 포함 6~20자</span>
                            </td>
                        </tr>
                        <tr>
                            <th>이름 <span class="red">*</span></th>
                            <td class="left">
                                <input type="text" v-model="uname" class="ml-20" style="width:200px;" id="name" name="name" value="">
                            </td>
                        </tr>
                        <tr>
                            <th style="width: 111px;">메모</th>
                            <td class="left">
                                <textarea type="text" class="ml-20 w-98per h-100px" id="introduce" v-model="memo" name="introduce" value="" />
                                </td>
                        </tr>
                    </tbody>
                </table>
                <div class="btns3 mgB80">
                    <a class="btn_sms mr-10 pointer" @click="moveAccountPage()">취소</a>
                    <a class="btn_cancle pointer" @click="register()">등록</a>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { IDFormatCheck, PWFormatCheck, EmptyCheck } from '@/utils/validation.js'
export default {
    data() {
        return {
            uname : "",
            uid : "",
            upw : "",
            memo  : "",
            isValidID: false, // id 중복 여부 false 일때 중복체크 안된상태
            btnDisabled:false
        };
    },


    mounted() {

    },

    methods: {

        // 센터명 중복체크 api
        checkID() {

            // 아이디 검사
            if (!EmptyCheck(this.uid, '아이디를')) {
                return false
            } else {
                if (!IDFormatCheck(this.uid)) {
                    return false
                }
            }

            var params = {
                id: this.uid
            }
            this.axios.get('/api/v1/user/dup', {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        alert('사용 가능한 아이디입니다.')
                        this.isValidID = true
                    } else {
                        alert('이미 사용중인 아이디입니다.')
                    }
                })
                .catch(err => {
                    console.log(err)
                    alert(err)
                })
        },

        // 관리자계정 페이지로 돌아가기
        moveAccountPage () {
            this.$router.push('/admin/account')
        },

        // 관리자 계정 등록
        register () {

            // 아이디 검사
            if (!EmptyCheck(this.uid, '아이디를')) {
                return false
            } else {
                if (!IDFormatCheck(this.uid)) {
                    return false
                }
            }

            // 아이디 중복체크 했는지 여부
            if (!this.isValidID) {
                alert('아이디 중복체크를 해주세요.')
                return false
            }

            // 비밀번호 검사
            if (!EmptyCheck(this.upw, '암호를')) {
                return false
            } else {
                if (!PWFormatCheck(this.upw)) {
                    return false
                }
            }

            // 이름 검사
            if (!EmptyCheck(this.uname, '성명을')) {
                return false
            }


            // TODO: 관리자 계정 등록 API호출
            var params = { 
                id: this.uid, 
                name: this.uname,
                pwd: this.upw,
                memo: this.memo,
            };

            if(this.btnDisabled){
                alert('저장중입니다. 잠시만 기다려주세요.');
                return false;
            }

            this.axios.post("/api/v1/admin/register", params).then((res) => {
                if(res.data.err === 0) {
                  this.$router.push('/admin/account')
                } else {
                  alert(res.data.err_msg);
                }
            }).finally(()=>{
                this.btnDisabled = false;
            })

        }
    }
}
</script>
